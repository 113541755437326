import { get, isEmpty, isUndefined, uniq } from 'lodash';
import { LIST_COLOR } from '../helps/listColor';
import { LongCodeFilterKey, ShortCodeFilterKey } from '~/constants/enum';
import { FilterEqualTypeInput } from '~/constants/ProductAttributeFilterInput';
import { styles as TextStyles } from '~/components/Text';
import { styles as ButtonStyles } from '~/components/Button';

export const convertFilesToImages = (files: File[]): IFile[] => {
    const filesArray = Array.from(files).map((file) => {
        const newFile: IFile = {
            name: file.name,
            size: file.size,
            type: file.type,
            url: URL.createObjectURL(file),
            file
        };

        return newFile;
    });

    return filesArray;
};

export const extractH2Elements = (str: string) => {
    const h2Regex = /<h2[^>]*>(.*?)<\/h2>/g;
    const matches = str?.match(h2Regex);

    if (!matches) {
        return [];
    }

    const h2Elements: string[] = [];

    for (const match of matches) {
        const titleStart = match.indexOf('>') + 1;
        const titleEnd = match.lastIndexOf('</');
        const title = match.substring(titleStart, titleEnd);

        h2Elements.push(title);
    }

    return h2Elements;
};

export const convertToSlug = (str: any, symbol?: string) => {
    if (str && typeof str === 'string') {
        // Chuyển hết sang chữ thường
        str = str.toLowerCase().trim();

        // xóa dấu
        str = str.replace(/(à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ)/g, 'a');
        str = str.replace(/(è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ)/g, 'e');
        str = str.replace(/(ì|í|ị|ỉ|ĩ)/g, 'i');
        str = str.replace(/(ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ)/g, 'o');
        str = str.replace(/(ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ)/g, 'u');
        str = str.replace(/(ỳ|ý|ỵ|ỷ|ỹ)/g, 'y');
        str = str.replace(/(đ)/g, 'd');

        // Xóa ký tự đặc biệt
        str = str.replace(/([^0-9a-z-\s])/g, '');

        // Xóa khoảng trắng thay bằng ký tự -
        str = str.replace(/(\s+)/g, symbol || '-');

        // Xóa ký tự - liên tiếp
        str = str.replace(/-+/g, symbol || '-');

        // xóa phần dự - ở đầu
        str = str.replace(/^-+/g, '');

        // xóa phần dư - ở cuối
        str = str.replace(/-+$/g, '');

        // return
        return str;
    } else return str;
};

export const striptTags = (content: string, numberSplice = 225) => {
    if (!!content) {
        const result = content.replace(/(<([^>]+)>)/gi, '');

        return result.slice(0, numberSplice);
    }

    return '';
};

function parseString(inputString: string, compileStyle: any = {}) {
    // Regex pattern
    if (!inputString) {
        return '';
    }
    let pattern = /^([A-Z][a-zA-Z0-9]*)_([a-z0-9_]+?)(_{2,}[a-zA-Z0-9_]+)?$/;
    // Match regex
    let matches = inputString.match(pattern);

    if (!matches) {
        return '';
    }

    // Extract components
    let namespace = matches[1];
    let className = matches[2];
    let suffix = matches[3] ? matches[3].substring(2) : ''; // Remove the leading '__' or more if suffix exists

    return `${compileStyle?.[namespace]?.[className]}`;
}
// Function to replace class names in HTML content
export function replaceClassNames(htmlContent: any, compileStyle: any = {}) {
    const defaultCompileStyle = {
        Text: TextStyles,
        Button: ButtonStyles
    };

    // return htmlContent;
    if (!htmlContent) return htmlContent;

    const originalClasses = extractClassAttributes(htmlContent);
    const replacedClasses = replaceSpecialClassNames(originalClasses, { ...compileStyle, ...defaultCompileStyle });
    const updatedHtmlContent = mapBackToHtmlContent(htmlContent, originalClasses, replacedClasses);
    return updatedHtmlContent;
}
export function parseVarFormCmsBlock(content: string): {
    typeProduct?: string;
    [x: string]: any;
} {
    try {
        const regex = /\{\{\s*var\s+((\w+)\s*=\s*"([^"]*)"\s*)+\}\}/g;
        // // return {};
        const match = content.match(regex);
        if (match) {
            let result: any = {};
            const keyValuePairs: any = content.match(/(\w+)\s*=\s*"([^"]*)"/g);
            keyValuePairs.forEach((pair: any) => {
                const [key, value] = pair.split('=').map((s: string) => s.trim().replace(/"/g, ''));
                result[key] = isNaN(value) ? value : parseInt(value, 10);
            });
            return result;
        } else {
            return {};
        }
    } catch (error) {
        console.log(error);
        return {};
    }
}

export const getHexCodeColor = (label: string): string => {
    const result = [...LIST_COLOR].find(
        (color: { ID: string; NAME: string; CODE: string; HEX: string }) =>
            color.NAME.toLowerCase() == label.toLowerCase()
    );

    return `#${result?.HEX ? result?.HEX : label.toLowerCase()}`;
};

export const converShortCodeFilterKey = (filter: any, options: 'endcode' | 'decode'): any => {
    const newFilter = { ...filter };
    Object.keys(newFilter).forEach((key) => {
        if (options === 'endcode') {
            if (ShortCodeFilterKey[key as keyof typeof ShortCodeFilterKey]) {
                const valueOfKey = newFilter[key];
                delete newFilter[key];
                newFilter[ShortCodeFilterKey[key as keyof typeof ShortCodeFilterKey]] = valueOfKey;
            }
        } else if (options === 'decode') {
            if (LongCodeFilterKey[key as keyof typeof LongCodeFilterKey]) {
                const valueOfKey = newFilter[key];
                delete newFilter[key];
                newFilter[LongCodeFilterKey[key as keyof typeof LongCodeFilterKey]] = valueOfKey;
            }
        }
    });
    return newFilter;
};
export const convertCleanFilterKey = (filter: any, moreFilterINput: any): any => {
    const newFilter: any = {};
    if (!isEmpty(filter) && !isUndefined(filter)) {
        Object.keys(filter).forEach((key: string) => {
            const itemByKey = filter[key];
            if ((key === 'min_price' || key === 'max_price') && filter['max_price'] && filter['min_price']) {
                newFilter['price'] = {
                    from: filter['min_price'],
                    to: filter['max_price']
                };
            } else if (!!uniq(FilterEqualTypeInput.concat(moreFilterINput || [])).includes(key) && !!itemByKey) {
                const currentQueries = typeof filter[key] === 'string' ? filter?.[key]?.split(',') : [];
                if (currentQueries.length > 0) {
                    newFilter[key] = { in: currentQueries };
                }
            }
        });
    }
    return newFilter;
};

// New Case
function extractClassAttributes(html: string) {
    const regex = /class="([^"]*)"/g;
    let matches;
    const classesArray = [];

    while ((matches = regex.exec(html)) !== null) {
        classesArray.push(matches[1]);
    }

    return classesArray;
}

function replaceSpecialClassNames(classesArray: string[], compileStyle: any) {
    // Regular expression to match the pattern [namespace]_[class]__xxxx
    const classRegex = /\b([A-Za-z]+)_[A-Za-z0-9_-]+(?:__[A-Za-z0-9_-]+)?\b/g;

    // // Replacement function to convert to compileStyle["namespace"].class_name
    const replacement = (match: string, namespace: string, className: string, suffix: string) => {
        // return `${compileStyle?.[namespace]?.[className]}`
        return parseString(match, compileStyle);
    };

    // // Perform the replacement
    //
    return classesArray.map((classString) => {
        return classString.replace(classRegex, replacement); // Bạn có thể thay đổi 'new-class-name' theo nhu cầu
    });
}
function mapBackToHtmlContent(html: string, originalClasses: string[], replacedClasses: string[]) {
    for (let i = 0; i < originalClasses.length; i++) {
        const originalClassAttr = `class="${originalClasses[i]}"`;
        const replacedClassAttr = `class="${replacedClasses[i]}"`;
        html = html.replace(originalClassAttr, replacedClassAttr);
    }
    return html;
}
